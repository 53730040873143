export function removeLangIdFromPathHelper(url: string, acceptedLangIds: string[]): string {
  let urlParts = url.split('/');
  urlParts = urlParts.filter((item: string) => item !== '');

  if (acceptedLangIds.includes(urlParts[0])) {
    urlParts.shift();
  }

  return '/' + urlParts.join('/');
}

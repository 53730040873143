import { Store } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';
import { Observable } from 'rxjs';
import { getRouterStateUrl, RouterStateUrl } from '../store/reducers/router.reducer';
import { Injectable } from '@angular/core';
import { filter, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouterStoreQueryService {
    constructor(private store: Store<RouterReducerState<RouterStateUrl>>) {}
    getRouterState(): Observable<RouterStateUrl> {
      return this.store
        .select(getRouterStateUrl)
        .pipe(filter((state) => state !== undefined));
    }
    getUrlWithoutQueryParams(): Observable<string> {
      return this.getRouterState().pipe(pluck('urlWithoutQueryParams'));
    }
}

import { MatDialog } from '@angular/material';
import { Component, OnInit } from '@angular/core';

import { SearchFormService } from '../../services/search.form.service';
import { SilpoVrService } from '../../services/silpo.vr.service';
import { ErrorModalComponent } from './error-modal-component/error.modal.component';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  public values;
  public dialogRef;
  constructor(private searchFormService: SearchFormService, public dialog: MatDialog, private vr: SilpoVrService) {}
  ngOnInit() {
    this.values = this.searchFormService.getValues();
  }
  onSearchClick() {
    const error = this.searchFormService.validateValues();
    // this.vr.openDialog(this.values);

    if (!error) {
      this.vr.openDialog(this.values);
      // this.searchFormService.startSearch();
    } else {
      this.openDialog(error);
    }
  }
  openDialog(error) {
    this.dialogRef = this.dialog.open(ErrorModalComponent, {
      data: {
        error
      }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      this.values.place.element.nativeElement.focus();
    });
  }
}

import { Component } from '@angular/core';
import { PageContentResultInterface } from '../../../../interfaces/page.content.result.interface';
import { HttpResponse } from '@angular/common/http';
import { SearchLocationService } from '../../../../services/search.location.service';
import { getCurrentLangId } from './../../../../modules/language/store/selectors/language.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/index';
import { filter, first, map } from 'rxjs/operators';
import {LanguageActionTypes, LanguageSelectAction} from '../../../../modules/language/store/actions/language.actions';
import {Actions, Effect} from '@ngrx/effects';
import {MetaService} from '../../../../modules/meta/meta.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {
  public text;
  public h1;
  public h1Small;
  public currentLangId$: Observable<string | null>;

  constructor(private searchLocationService: SearchLocationService,
              private store: Store<any>,
              private actions$: Actions,
              private metaService: MetaService) {
    this.currentLangId$ = this.store.select(getCurrentLangId);
    this.currentLangId$.pipe(
      filter((currentLangId: string | null): currentLangId is string => currentLangId !== null),
      first(),
      map((currentLangId) => {
        this.loadPageContent(currentLangId);
      })
    ).subscribe();

  }
  @Effect()
  select$ = this.actions$.ofType<LanguageSelectAction>(LanguageActionTypes.LanguageSelectAction).pipe().subscribe((action) => {
    this.loadPageContent(action.payload.langId);
  });
  loadPageContent(currentLangId) {
    this.searchLocationService.getPageContent('/', currentLangId)
      .subscribe((httpResponse: HttpResponse<PageContentResultInterface>) => {
        if (httpResponse.body.success && httpResponse.body.page) {
          const page = httpResponse.body.page;
          this.text = page.text;
          this.h1 = page.h1;
          this.h1Small = page.title;
          this.metaService.setTitleDescription(page.meta_title, page.meta_description);
          if (page.meta_keywords.length) {
            this.metaService.setMetaKeywords(page.meta_keywords);
          }
        }
      });
  }
}


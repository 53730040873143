import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import {  map, tap } from 'rxjs/operators';
import { RouterActionTypes } from '../actions/router.actions';
import {Inject, Injectable, Optional} from '@angular/core';
import { Response } from 'express';
import { RESPONSE } from '@nguniversal/express-engine/tokens';

import { PlatformQueryService } from '../../../environment/services/platform-query.service';

@Injectable({
  providedIn: 'root'
})
export class RouterEffects {
  @Effect({dispatch: false}) navigate$: Observable<any>;
  @Effect({dispatch: false}) navigateBack$: Observable<any>;
  @Effect({dispatch: false}) navigateForward$: Observable<any>;

  constructor(private actions$: Actions,
              private router: Router,
              private location: Location,
              private platformService: PlatformQueryService,
              @Inject(RESPONSE)
              @Optional()
              private response: Response) {
    this.navigate$ = this.actions$
      .pipe(
        ofType(RouterActionTypes.RouterGoAction),
        map((action: any) => {
          return action.payload;
        }),
        tap(({path, queryParams, extras}) => {
        if (this.platformService.isServer()) {
          if (this.response === null) {
            throw new Error('Response object in not set.');
          }
          this.response.status(301);
          this.response.location(path + this.objectToQueryString(queryParams));
        } else if (this.platformService.isBrowser()) {
            this.router.navigate(path, Object.assign({queryParams}, extras));
        } else {
          throw new Error('Unknown platform.');
        }
      }),
    );
    this.navigateBack$ = this.actions$
      .ofType(RouterActionTypes.RouterBackAction)
      .pipe(tap(() => this.location.back()));
    this.navigateForward$ = this.actions$
      .ofType(RouterActionTypes.RouterForwardAction)
      .pipe(tap(() => this.location.forward()));
  }

  objectToQueryString(object) {
    let result: String = '';
    if (!!object) {
      result = `?${Object.keys(object)
        .map((key) => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`;
        })
        .join('&')}`;
    }
    return result;
  }
}

import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-numberpicker',
  templateUrl: './numberpicker.component.html',
  styleUrls: ['./numberpicker.component.scss']
})

export class NumberpickerComponent implements OnInit {
    public numberPickerValue: number;
    private decreaseButtonDisabled: Boolean = false;
    private increaseButtonDisabled: Boolean = false;

    @Input() min: 0;
    @Input() max: 30;
    @Input() default: number;
    @Input() type: string;
    @Input() name: string;
    @Output() onChange: EventEmitter<number> = new EventEmitter();

    constructor() {
        this.numberPickerValue = 0;
    }

    ngOnInit(): void {
        this.setDefaultValue();
        this.checkButtons();
    }

    increase(event: any): void {
        event.preventDefault();
        if (this.numberPickerValue < this.max) {
            this.numberPickerValue++;
            this.onChange.emit(this.numberPickerValue);
        }
        this.checkButtons();
    }

    decrease(event: any): void {
        event.preventDefault();
        if (this.getValue() > this.min) {
            this.numberPickerValue--;
            this.onChange.emit(this.numberPickerValue);
        }
        this.checkButtons();
    }

    checkButtons(): void {
        this.increaseButtonDisabled = false;

        this.decreaseButtonDisabled = this.getValue() <= this.min;
        if (this.numberPickerValue >= this.max) {
            this.increaseButtonDisabled = true;
        }
    }

    setDefaultValue(): void {
        if (this.default === null || this.default === undefined) {
            this.setValue(0);
        } else {
            this.setValue(this.default);
        }
    }

    getValue(): number {
        return this.numberPickerValue;
    }

    setValue(value: number): void {
        this.numberPickerValue = value;
    }


}

import { Component, HostListener, OnInit } from '@angular/core';
import { SearchFormService } from '../../services/search.form.service';
import { SilpoVrService } from '../../services/silpo.vr.service';

const maxResponsiveWidth = 768;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [SearchFormService, SilpoVrService]
})
export class SearchComponent implements OnInit {
  public values;
  public touchUi;
  constructor (public searchFormService: SearchFormService) {}
  ngOnInit() {
    this.values = this.searchFormService.getValues();
    this.touchUi = maxResponsiveWidth > ((typeof window !== 'undefined') ? window.innerWidth : 1024);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.touchUi = maxResponsiveWidth > event.target.innerWidth;
  }
}

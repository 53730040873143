import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatListModule, MatSidenavModule } from '@angular/material';

import { LanguageModule } from '../../modules/language/language.module';
import { LanguageSelectModule } from '../../modules/language-select/language-select.module';
import { LayoutContainerComponent } from './layout-container.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LayoutMobileComponent } from './components/layout-mobile/layout-mobile.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { ToolbarComponent } from '../../components/toolbar/toolbar.component';
import { PrivacyPolicyPopupComponent } from '../../components/privacy-policy-popup/privacy-policy-popup.component';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    MatButtonModule,
    LanguageSelectModule,
    MatSidenavModule,
    MatListModule
  ],
  declarations: [
    LayoutContainerComponent,
    LayoutComponent,
    LayoutMobileComponent,
    FooterComponent,
    ToolbarComponent,
    PrivacyPolicyPopupComponent
  ],
  exports: [LayoutContainerComponent],
})
export class LayoutModule {}

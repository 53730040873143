import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { filter, first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { environment } from '../../environments/environment';
import { getCurrentLangId } from './../modules/language/store/selectors/language.selectors';

import { LocationResultsInterface } from '../interfaces/location.results.interface';
import { PageContentResultInterface } from '../interfaces/page.content.result.interface';

@Injectable()
export class SearchLocationService {
  public currentLangId$: Observable<string>;

  constructor(private store: Store<any>, private http: HttpClient) {
    this.currentLangId$ = this.store.select(getCurrentLangId);
  }

  getLocations(text): Observable<Observable<HttpResponse<LocationResultsInterface>>> {
    return this.currentLangId$.pipe(
      filter((currentLangId: string | null): currentLangId is string => currentLangId !== null),
      first(),
      map((currentLangId) => {
        return this.makeRequest(text, currentLangId);
      })
    );
  }

  makeRequest(text, lang) {
    const headers = new HttpHeaders().set('api-key', environment.bookingApiKey);
    const url = `${environment.apiUrl}auto_complete/${lang}?search=${text}`;
    return this.http.get<LocationResultsInterface>( url, { headers, observe: 'response' });
  }

  getPageContent(pagePath, lang): Observable<HttpResponse<PageContentResultInterface>> {
    const headers = new HttpHeaders().set('api-key', environment.bookingApiKey);
    return this.http.post<PageContentResultInterface>(`${environment.apiUrl}content/page/${lang}.json`, {path: '/'}, {observe: 'response', headers});
  }
}

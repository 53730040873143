import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppContainerComponent } from './app-container.component';

import { SearchLocationService } from './services/search.location.service';
import { WindowRef } from './services/window.ref';
import { PageNotFoundComponent } from './components/page-not-found/page.not.found.component';
import { MenuService } from './services/menu.service';

import { LanguageModule } from './modules/language/language.module';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { RouterStoreModule } from './modules/router-store/router-store.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LayoutModule } from './pages/layout/layout.module';
import { HomePageModule } from './pages/home-page/home-page.module';
import { TranslateServerLoader } from './modules/language/handlers/translate-server-loader';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PlatformQueryService } from './modules/environment/services/platform-query.service';
import { EnvironmentModule } from './modules/environment/environment.module';
import { CookieService } from 'ngx-cookie-service';

export const metaReducers: MetaReducer<any>[] = !environment.production ? [storeFreeze] : [];
export function LoaderFactory(platformService: PlatformQueryService, httpClient: HttpClient): TranslateLoader {
  let translateLoader: TranslateLoader;
  if (platformService.isServer()) {
    translateLoader = new TranslateServerLoader(httpClient);
  } else if (platformService.isBrowser()) {
    translateLoader = new TranslateServerLoader(httpClient);
  } else {
    throw new Error('Unknown platform.');
  }

  return translateLoader;
}

@NgModule({
  declarations: [
    AppContainerComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'hotels-browser' }),
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot({}, { metaReducers }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
    }),
    RouterStoreModule,
    EnvironmentModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: LoaderFactory,
        deps: [PlatformQueryService, HttpClient],
      },
    }),
    LanguageModule.forRoot(environment.acceptedLangIds, environment.defaultLangId),
    LayoutModule,
    HomePageModule,
    FontAwesomeModule
  ],
  providers: [
    SearchLocationService,
    CookieService,
    WindowRef,
    MenuService,
    { provide: MAT_DATE_LOCALE, useValue: environment.defaultLangId}
  ],
  bootstrap: [AppContainerComponent]
})

export class AppModule {}

import {Component, Input} from '@angular/core';
import {PeopleInterface} from '../../../interfaces/people.interface';
import {ChildInterface} from '../../../interfaces/child.interface';

@Component({
  selector: 'app-people-picker',
  templateUrl: './people.picker.component.html',
  styleUrls: ['./people.picker.component.scss']
})
export class PeoplePickerComponent {
  @Input() people: PeopleInterface;
  @Input() open: boolean;

  onAdultChanged(value: number): void {
    this.people.adults = value;
  }
  onChildrenChanged(value: number) {
      if (value < this.people.children.length) {
        this.people.children = this.people.children.slice(0, value - 1);
      }
      if (value > this.people.children.length) {
        this.people.children.push({age: 0});
      }
  }
  onChildrenAgeChanged(child: ChildInterface, value: number): void {
    child.age = value;
  }

}

import { Component, OnInit } from '@angular/core';
import {SearchFormService} from '../../services/search.form.service';
import {PlaceInterface} from '../../interfaces/place.interface';

import {LocationInterface} from '../../interfaces/location.interface';

@Component({
  selector: 'app-place',
  templateUrl: './place.component.html',
  styleUrls: ['./place.component.scss'],

})
export class PlaceComponent implements OnInit {
  place: PlaceInterface;
  locations: LocationInterface[];
  showInput = true;
  showDropdown = false;
  showResult = false;
  placeTimeoutId = null;

  constructor(private searchFormService: SearchFormService) { }

  ngOnInit() {
    this.place = this.searchFormService.getValues().place;
    if (this.place.location) {
      this.searchFormService.changePlace(this.place.text,  () => {
        this.locations = this.searchFormService.getValues().locations;
        this.showDropdown = false;
        this.showInput = false;
        this.showResult = true;
      });
    }
  }

  onPlaceChange() {
    if (this.place.text.length >= 3) {
      if (this.placeTimeoutId) {
        clearTimeout(this.placeTimeoutId);
      }
      // Start searching only after customer stop typing
      this.placeTimeoutId = setTimeout(() => {
        this.searchFormService.changePlace(this.place.text, this.onPlaceChanged());
      }, 400);
    } else {
      this.showDropdown = false;
    }
  }

  onPlaceChanged() {
    const self = this;
    return function () {
      self.locations = self.searchFormService.getValues().locations;
      self.showDropdown = self.locations.length > 0;
    };
  }

  setPlaceInputElement($event) {
    this.place.element = $event;
  }

  onLocationSelected(location) {
    this.place.location = location;
    this.showDropdown = false;
    this.showInput = false;
    this.showResult = true;
    this.searchFormService.onChange();
  }

  onResultClick() {
    this.showResult = false;
    this.showInput = true;
    this.showDropdown = true;
  }
}

import { routerReducer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CustomRouterStateSerializer } from './store/reducers/router.reducer';
import { RouterEffects} from './store/effects/router.effects';
import { NgModule } from '@angular/core';
import { Optional, SkipSelf } from '@angular/core';



@NgModule({
  imports: [StoreModule.forFeature('RouterStore', routerReducer),
    EffectsModule.forFeature([RouterEffects]),
    StoreRouterConnectingModule],
  providers: [
      { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer }
    ]
})
export class RouterStoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: RouterStoreModule) {
      if (parentModule) {
        throw new Error('RouterStoreModule is already loaded.');
      }
  }
}

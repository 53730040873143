import { UrlMatchResult, UrlSegment } from '@angular/router';
import { environment } from '../../../../environments/environment';

export function langRoutesMatcher(segments: UrlSegment[]): UrlMatchResult {
  let result: UrlMatchResult = {
    consumed: [],
  };

  if (segments.length > 0 && environment.acceptedLangIds.includes(segments[0].path)) {
    result = {
      consumed: [segments[0]],
      posParams: { langId: segments[0] },
    };
  }

  return result;
}

import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error.modal.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./error.modal.component.scss']
})
export class ErrorModalComponent {
  public error;
  constructor(@Inject(MAT_DIALOG_DATA) data: any) {
    this.error = data.error;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PeopleInterface } from '../../interfaces/people.interface';
import { PeopleModalPickerComponent } from './people-modal-picker-component/people.modal.picker.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})
export class PeopleComponent implements OnInit {
  public isPickerOpened;
  public dialogRef;
  public dialogPeople: PeopleInterface;

  @Input() people: PeopleInterface;
  @Input() touchUi: boolean;

  @Output() peopleChangeEvent = new EventEmitter();

  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    this.isPickerOpened = false;
  }
  switchPeoplePicker() {
    this.dialogPeople = JSON.parse(JSON.stringify(this.people));
    this.isPickerOpened = false;
    this.openDialog();
  }

  onClickedOutside(e: Event) {
      if (this.isPickerOpened) {this.isPickerOpened = !this.isPickerOpened;}
  }
  openDialog() {
    this.dialogRef = this.dialog.open(PeopleModalPickerComponent, {
      data: {
        people: this.dialogPeople
      }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      // If cancel button clicked
      if (result) {
        Object.assign(this.people, this.dialogPeople);
        this.peopleChangeEvent.emit();
      }
    });
  }
}


import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { DeviceType } from '../../modules/device/types/device.type';
import { DeviceService } from '../../modules/device/services/device.service';
import { RouterStoreQueryService } from '../../modules/router-store/services/router-store-query.service';

@Component({
  selector: 'app-layout-container',
  template: `
    <app-layout
      class="d-none d-md-block"
      *ngIf="(device$ | async) === 'desktop'"
      [urlWithoutQueryParams]="urlWithoutQueryParams$ | async"
    ></app-layout>
    <app-layout-mobile
      class="d-md-none"
      *ngIf="(device$ | async) === 'mobile'"
      [urlWithoutQueryParams]="urlWithoutQueryParams$ | async"
    ></app-layout-mobile>
  `,
})
export class LayoutContainerComponent implements OnInit {
  urlWithoutQueryParams$: Observable<string>;
  device$: Observable<DeviceType>;

  constructor(
    private store: Store<any>,
    private routerStoreQueryService: RouterStoreQueryService,
    private deviceService: DeviceService,
  ) {}

  ngOnInit(): void {
    this.urlWithoutQueryParams$ = this.routerStoreQueryService.getUrlWithoutQueryParams();
    this.device$ = this.deviceService.getDeviceType();
  }
}

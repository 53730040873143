import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';

import { filter, map, tap, withLatestFrom } from 'rxjs/operators';

import { DEFAULT_LANG_ID } from '../../tokens/default-lang-id.token';
import { ACCEPTED_LANG_IDS } from '../../tokens/accepted-lang-ids.token';
import { LanguageActionTypes, LanguageSelectAction, LanguageUrlChangeAction } from '../actions/language.actions';
import { getCurrentLangId } from '../selectors/language.selectors';
import { removeLangIdFromPathHelper } from '../../helpers/remove-lang-id-from-path.helper';
import { RouterStateUrl } from '../../../router-store/store/reducers/router.reducer';
import { RouterStoreQueryService } from '../../../router-store/services/router-store-query.service';
import { RouterGoAction } from '../../../router-store/store/actions/router.actions';

@Injectable({
  providedIn: 'root',
})
export class LanguageEffects {
  constructor(
    private actions$: Actions,
    @Inject(DEFAULT_LANG_ID) private defaultLangId: string,
    @Inject(ACCEPTED_LANG_IDS) private acceptedLangIds: string[],
    private routerStoreQueryService: RouterStoreQueryService,
    private store: Store<any>,
  ) {}

  @Effect()
  urlChange$ = this.actions$.ofType<RouterNavigationAction<RouterStateUrl>>(ROUTER_NAVIGATION).pipe(
    filter(
      (action: RouterNavigationAction<RouterStateUrl>) =>
        action.payload.routerState.urlWithoutQueryParams !== '/redirect',
    ),
    tap((action: RouterNavigationAction<RouterStateUrl>) => {
      const langIdFromUrl: string | undefined = action.payload.routerState.params['langId'];

      if (langIdFromUrl === this.defaultLangId) {
        const urlWithoutLangId = removeLangIdFromPathHelper(
          action.payload.routerState.urlWithoutQueryParams,
          this.acceptedLangIds,
        );

        this.store.dispatch(
          new RouterGoAction({
            path: [urlWithoutLangId],
            queryParams: action.payload.routerState.queryParams,
            extras: { replaceUrl: true },
          }),
        );
      }
    }),
    filter(
      (action: RouterNavigationAction<RouterStateUrl>) =>
        action.payload.routerState.params['langId'] !== this.defaultLangId,
    ),
    map((action: RouterNavigationAction<RouterStateUrl>) => {
      let langIdFromUrl: string | undefined = action.payload.routerState.params['langId'];
      if (langIdFromUrl === undefined) {
        langIdFromUrl = this.defaultLangId;
      }
      return langIdFromUrl;
    }),
    withLatestFrom(this.store.select(getCurrentLangId)),
    filter(([nextLangId, prevLangId]: any) => nextLangId !== prevLangId),
    map(([nextLangId]: any) => {
      if (!this.acceptedLangIds.includes(nextLangId)) {
        throw new Error(`Unknown langId ${nextLangId} in route.`);
      }
      return new LanguageUrlChangeAction({ langId: nextLangId });
    }),
  );

  @Effect()
  select$ = this.actions$.ofType<LanguageSelectAction>(LanguageActionTypes.LanguageSelectAction)
    .pipe(
      withLatestFrom(this.routerStoreQueryService.getRouterState()),
      filter(([action, routerStateUrl]: [LanguageSelectAction, RouterStateUrl]) => {
        const nextLangId: string = action.payload.langId;
        const prevLangId: string = routerStateUrl.params['langId'] || this.defaultLangId;
        return nextLangId !== prevLangId;
      }),
      map(([action, routerStateUrl]: [LanguageSelectAction, RouterStateUrl]) => {
        const nextLangId: string = action.payload.langId;

        if (!this.acceptedLangIds.includes(nextLangId)) {
          throw new Error(`Unknown langId ${nextLangId} select action.`);
        }

        let urlWithoutLangId;
        if (nextLangId === this.defaultLangId) {
          urlWithoutLangId = removeLangIdFromPathHelper(routerStateUrl.urlWithoutQueryParams, this.acceptedLangIds);
        } else {
          urlWithoutLangId =
            `/${nextLangId}${removeLangIdFromPathHelper(routerStateUrl.urlWithoutQueryParams, this.acceptedLangIds)}`;
        }
        return new RouterGoAction({
          path: [urlWithoutLangId],
          queryParams: routerStateUrl.queryParams
        });
      })
    );
}

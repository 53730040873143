// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  // apiUrl: 'http://localhost:3000/',
  // apiUrl: 'http://hotels.silpovoyage.kirill.devel.silpovoyage.com.ua/api/',
  // apiUrl: 'http://hotels.silpovoyage.local.devel.silpovoyage.com.ua/api/',
  apiUrl: 'https://hotels.silpovoyage.ua/api/',
  acceptedLangIds: ['ru', 'uk'],
  defaultLangId: 'uk',
  bookingApiKey: '175918b2-b372-11e8-96f8-529269fb1459'
};

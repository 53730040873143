import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { PeopleInterface } from '../../../interfaces/people.interface';
import { SearchInterface } from '../../../interfaces/search.interface';

@Component({
  selector: 'app-people-modal-picker',
  templateUrl: './people.modal.picker.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./people.modal.picker.component.scss']
})
export class PeopleModalPickerComponent {
  public faTimes = faTimes;
  public people: PeopleInterface;
  public adultCounts: number[];
  public childrenAges: number[];
  public isChildrenPickerOpen = false;

  constructor(@Inject(MAT_DIALOG_DATA) data: SearchInterface) {
    this.people = data.people;
    this.adultCounts = Array.from(new Array(10), (val, index) => index + 1);
    this.childrenAges = Array.from(new Array(18), (val, index) => index);
  }

  onAdultChanged(value: number): void {
    this.people.adults = value;
  }

  openChildPicker(el): void {
    if (this.isChildrenPickerOpen) {
      this.isChildrenPickerOpen = false;
      return;
    }
    if (this.people.children.length < 10) {
      this.isChildrenPickerOpen = true;
      setTimeout(() => {el.scrollIntoView()}, 0);
    }
  }

  addChild(age): void {
    if (this.people.children.length < 10) {
      this.people.children.push({age});
    }
    this.isChildrenPickerOpen = false;
  }

  removeChild(child): void {
    const index: number = this.people.children.indexOf(child);
    if (index !== -1) {
      this.people.children.splice(index, 1);
    }
  }
}

import { Action } from '@ngrx/store';
import { NavigationExtras, Params } from '@angular/router';
export enum RouterActionTypes {
  RouterGoAction = '[RouterStore] Go',
  RouterBackAction = '[RouterStore] Back',
  RouterForwardAction = '[RouterStore] Forward',
}
interface Payload {
  path: any[];
  queryParams?: Params | null;
  extras?: NavigationExtras;
}

export class RouterGoAction implements Action {
  payload: Payload;
  type: RouterActionTypes;
  constructor(payload: Payload) {
    this.payload = payload;
    this.type = RouterActionTypes.RouterGoAction;
  }
}
export class RouterBackAction implements Action {
  type: RouterActionTypes;
  constructor() {
    this.type = RouterActionTypes.RouterBackAction;
  }
}
export class RouterForwardAction implements Action {
  type: RouterActionTypes;
  constructor() {
    this.type = RouterActionTypes.RouterForwardAction;
  }
}
export type RouterActions = RouterGoAction | RouterBackAction | RouterForwardAction;

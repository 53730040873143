import { Component, OnInit } from '@angular/core';
import { MetaService } from '../../modules/meta/meta.service';
import { TRANSLATE } from '../../modules/language/helpers/translation-marker.helper';
import { TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-home-page-container',
  template: `<app-home-page></app-home-page>`,
})
export class HomePageContainerComponent implements OnInit {
  constructor(private metaService: MetaService, private translate: TranslateService) {}

  ngOnInit(): void {
    // this.metaService.setTitleDescription(
    //   TRANSLATE('HomePageContainerComponent.MetaTitle'),
    //   TRANSLATE('HomePageContainerComponent.MetaDescription'),
    // );
    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.metaService.setTitleDescription(
    //     TRANSLATE('HomePageContainerComponent.MetaTitle'),
    //     TRANSLATE('HomePageContainerComponent.MetaDescription'),
    //   );
    // });
  }
}

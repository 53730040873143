import { Inject, Injectable, Optional, Renderer2, RendererFactory2 } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import { BehaviorSubject, Observable } from 'rxjs';

import { DeviceType } from '../types/device.type';
import { PlatformQueryService } from '../../environment/services/platform-query.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  renderer: Renderer2;

  private readonly deviceType: BehaviorSubject<DeviceType>;

  constructor(
    @Optional()
    @Inject(REQUEST)
    private request: Request,
    private platformService: PlatformQueryService,
    rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.deviceType = new BehaviorSubject<DeviceType>(this.detectDevice());

    if (this.platformService.isBrowser()) {
      this.renderer.listen('window', 'resize', () => {
        this.deviceType.next(this.detectDevice());
      });
    }
  }

  getDeviceType(): Observable<DeviceType> {
    return this.deviceType.asObservable();
  }

  private detectDevice(): DeviceType {
    if (this.platformService.isServer()) {
      if (this.request === null) {
        throw new Error('Request object is not set.');
      }

      const userAgent = this.request.header('User-Agent');

      if (userAgent === undefined) {
        throw new Error('User-Agent is not defined.');
      }

      return userAgent.indexOf('Mobi') >= 0 ? 'mobile' : 'desktop';
    } else if (this.platformService.isBrowser()) {
      return window.innerWidth < 768 ? 'mobile' : 'desktop';
    } else {
      throw new Error('Unknown platform.');
    }
  }
}

import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

import { DEFAULT_LANG_ID } from '../../tokens/default-lang-id.token';
import { getCurrentLangId } from '../../store/selectors/language.selectors';

@Injectable({
  providedIn: 'root',
})
export class LanguageInitCommandService {
  constructor(
    @Inject(DEFAULT_LANG_ID) private defaultLangId: string,
    private store: Store<any>,
    private translateService: TranslateService,
  ) {
    this.initTranslations();
  }

  private initTranslations(): void {
    this.translateService.setDefaultLang(this.defaultLangId);

    this.store
      .select(getCurrentLangId)
      .pipe(filter((currentLangId: string | null): currentLangId is string => currentLangId !== null))
      .subscribe((currentLangId: string) => {
        this.translateService.use(currentLangId);
      });
  }

  use(): void {}
}

import { Component, Input } from '@angular/core';

import { routeAnimation } from '../../animations/route.animation';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [routeAnimation],
})
export class LayoutComponent {
  @Input() urlWithoutQueryParams: string;

  animationsDisabled = true;

  constructor() {
    setTimeout(() => {
      this.animationsDisabled = false;
    }, 0);
  }
}

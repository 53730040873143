import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { I18nService } from './../../../services/i18n.service';
import { HttpClient } from '@angular/common/http';

export class TranslateServerLoader implements TranslateLoader {
  private i18nService: I18nService;
  constructor(private http: HttpClient) {
    this.i18nService = new I18nService(http);
  }

  getTranslation(lang: string): Observable<any> {
    return this.i18nService.getTranslations(lang);
  }
}

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, first, tap, withLatestFrom } from 'rxjs/operators';

import { DEFAULT_LANG_ID } from '../tokens/default-lang-id.token';
import { getCurrentLangId } from '../store/selectors/language.selectors';
import { RouterStoreQueryService } from '../../router-store/services/router-store-query.service';
import { RouterStateUrl } from '../../router-store/store/reducers/router.reducer';
import { RouterGoAction } from '../../router-store/store/actions/router.actions';

@Component({
  selector: 'app-language-redirect-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ``,
})
export class LanguageRedirectContainerComponent implements OnInit {
  constructor(
    private store: Store<any>,
    @Inject(DEFAULT_LANG_ID) private defaultLangId: string,
    private routerStoreQueryService: RouterStoreQueryService,
  ) {}

  ngOnInit() {
    this.routerStoreQueryService
      .getRouterState()
      .pipe(
        tap((state: RouterStateUrl) => {
          if (state.queryParams['urlWithoutLangId'] === undefined) {
            throw new Error(`Query param 'urlWithoutLangId' is missed.`);
          }
        }),
        filter((state: RouterStateUrl) => state.queryParams['urlWithoutLangId'] !== undefined),
        withLatestFrom(this.store.select(getCurrentLangId)),
        first(),
      )
      .subscribe(([routerStateUrl, currentLangId]: [RouterStateUrl, string | null]) => {
        const canLoadLangId = routerStateUrl.queryParams['canLoadLangId'];
        let urlToNavigate = routerStateUrl.queryParams['urlWithoutLangId'];

        let langIdInUrl: string | undefined = currentLangId !== null ? currentLangId : canLoadLangId;
        if (langIdInUrl === this.defaultLangId || langIdInUrl === undefined) {
          langIdInUrl = '';
        }

        if (urlToNavigate.charAt(0) === '/') {
          if (urlToNavigate === '/') {
            urlToNavigate = `/${langIdInUrl}`;
          } else if (langIdInUrl === '') {
            urlToNavigate = `${urlToNavigate}`;
          } else {
            urlToNavigate = `/${langIdInUrl}${urlToNavigate}`;
          }
        } else {
          urlToNavigate = `${routerStateUrl.urlWithoutQueryParams}/${urlToNavigate}`;
        }

        const filteredRedirectParams = {
          urlWithoutLangId: undefined,
          canLoadLangId: undefined,
        };

        this.store.dispatch(
          new RouterGoAction({
            path: [urlToNavigate],
            queryParams: { ...routerStateUrl.queryParams, ...filteredRedirectParams },
          }),
        );
      });
  }
}

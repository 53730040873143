import {Component, Input, Output, EventEmitter, Inject} from '@angular/core';
import {DatePickerInterface} from '../../interfaces/date.picker.interface';
import {ViewEncapsulation } from '@angular/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {Actions, Effect} from '@ngrx/effects';
import {LanguageActionTypes, LanguageSelectAction} from '../../modules/language/store/actions/language.actions';
import { getCurrentLangId } from './../../modules/language/store/selectors/language.selectors';
import {Observable} from 'rxjs/index';
import {Store} from '@ngrx/store';
import {filter, first} from 'rxjs/operators';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class DatePickerComponent {
  @Input() value: DatePickerInterface;
  @Input() placeholder: string;
  @Input() touchUi: boolean;
  @Output() dateChange: EventEmitter<any> = new EventEmitter();
  public currentLangId$: Observable<string | null>;
  constructor(
    private adapter: DateAdapter<any>,
    private actions$: Actions,
    private store: Store<any>) {
    this.currentLangId$ = this.store.select(getCurrentLangId);
    this.currentLangId$.pipe(
      filter((currentLangId: string | null): currentLangId is string => currentLangId !== null),
      first()
    ).subscribe((langId) => {
      this.adapter.setLocale(langId);
    });
  }
  @Effect()
  select$ = this.actions$.ofType<LanguageSelectAction>(LanguageActionTypes.LanguageSelectAction).pipe().subscribe((action) => {
    this.adapter.setLocale(action.payload.langId);
  });
  dateChanged($event) {
    this.dateChange.emit($event);
  }
}



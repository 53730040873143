import { Action } from '@ngrx/store';

export enum LanguageActionTypes {
  LanguageUrlChangeAction = '[Language] Url change',
  LanguageSelectAction = '[Language] Select',
}

export class LanguageUrlChangeAction implements Action {
  readonly type = LanguageActionTypes.LanguageUrlChangeAction;

  constructor(public payload: { langId: string }) {}
}

export class LanguageSelectAction implements Action {
  readonly type = LanguageActionTypes.LanguageSelectAction;

  constructor(public payload: { langId: string }) {}
}

export type LanguageActions = LanguageUrlChangeAction | LanguageSelectAction;

import { HttpClient, HttpHeaders, HttpResponse , HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/index';
import { map } from 'rxjs/operators';

@Injectable()
export class I18nService {
  constructor(private http: HttpClient) {}
  getTranslations(lang: string[2] = 'ru'): Observable<any> {
    const headers = new HttpHeaders().set('api-key', environment.bookingApiKey);
    const url = `${environment.apiUrl}content/${lang}.json`;
    // console.log('Requesting url', url);
    return this.http.get( url, { headers }).pipe(map((x: any) => x.translations));
  }
}

import { Component, Input } from '@angular/core';

import { routeAnimation } from '../../animations/route.animation';

@Component({
  selector: 'app-layout-mobile',
  templateUrl: './layout-mobile.component.html',
  styleUrls: ['./layout-mobile.component.scss'],
  animations: [routeAnimation],
})
export class LayoutMobileComponent {
  @Input() urlWithoutQueryParams: string;

  animationsDisabled = true;

  constructor() {
    setTimeout(() => {
      this.animationsDisabled = false;
    }, 0);
  }
}

import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../services/menu.service';
import { Menu } from '../../classes/menu';
import {Observable} from 'rxjs/internal/Observable';
import {first} from 'rxjs/operators';
import {LanguageActionTypes, LanguageSelectAction} from '../../modules/language/store/actions/language.actions';
import {Actions, Effect} from "@ngrx/effects";

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  public menu: Menu;
  public innerWidth: any;
  public mOpened = false;
  public isOpened = false;
  public languages = {
    ru: {href: '/', text: 'РУС'},
    uk: {href: '/uk', text: 'УКР'}
  };
  public lang;
  public availableLanguages = [];
  constructor(private menuService: MenuService, private actions$: Actions) {
    menuService.getMenu().pipe(first()).subscribe(menu => this.menu = menu);
    this.innerWidth = (typeof window !== 'undefined') ? window.innerWidth : 1024;
  }

  @Effect()
  select$ = this.actions$.ofType<LanguageSelectAction>(LanguageActionTypes.LanguageSelectAction).pipe().subscribe((action) => {
    this.menu = this.menuService.getMenuByLang(action.payload.langId);
  });

  ngOnInit() {
    this.getAvailableLanguages();
  }
  switchOpen() {
      this.isOpened = !this.isOpened;
  }

  menuOpen() {
      this.mOpened = !this.mOpened;
  }
  getAvailableLanguages() {
    this.availableLanguages = [];
    Object.keys(this.languages).forEach((locale) => {
      if (locale !== this.lang) {
        this.availableLanguages.push(this.languages[locale]);
      }
    });
  }
}

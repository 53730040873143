import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ACCEPTED_LANG_IDS } from '../language/tokens/accepted-lang-ids.token';
import { getCurrentLangId } from '../language/store/selectors/language.selectors';
import { LanguageSelectAction } from '../language/store/actions/language.actions';

@Component({
  selector: 'app-language-select-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <app-language-select
      [acceptedLangIds]="acceptedLangIds"
      [currentLangId]="currentLangId$ | async"
      (selectValueEvent)="onSelectValueEvent($event)"
     ></app-language-select>
  `,
})
export class LanguageSelectContainerComponent implements OnInit {
  currentLangId$: Observable<string | null>;

  constructor(private store: Store<any>, @Inject(ACCEPTED_LANG_IDS) public acceptedLangIds: string[]) {}

  ngOnInit(): void {
    this.currentLangId$ = this.store.select(getCurrentLangId);
  }

  onSelectValueEvent(value: string): void {
    this.store.dispatch(new LanguageSelectAction({ langId: value }));
  }
}

import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { RouterReducerState, RouterStateSerializer } from '@ngrx/router-store';
import { Data, Params, RouterStateSnapshot } from '@angular/router';
export interface RouterStateUrl {
  url: string;
  urlWithoutQueryParams: string;
  queryParams: Params;
  params: Params;
  data: Data;
}
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;
    const queryParamsIndex = url.indexOf('?');
    const urlWithoutQueryParams = queryParamsIndex === -1 ? url : url.slice(0, queryParamsIndex);
    let state = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const { params } = state;
    const { data } = state;
    return { url, urlWithoutQueryParams, queryParams, params, data };
  }
}

export const getRouterStoreState: MemoizedSelector<object, RouterReducerState<RouterStateUrl>> = createFeatureSelector('RouterStore');
export const getRouterStateUrl: MemoizedSelector<object, RouterStateUrl> = createSelector(getRouterStoreState, (state) => {
  return state && state.state;
});

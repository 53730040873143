import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';

import { langRoutesMatcher } from './modules/language/matchers/lang-routes.matcher';
import { LanguageRedirectContainerComponent } from './modules/language/containers/language-redirect-container.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: './pages/home-page/home-page.module#HomePageModule',
  },
  {
    path: 'about',
    loadChildren: './pages/about-page/about-page.module#AboutPageModule',
  },
  {
    path: '**',
    loadChildren: './pages/default-page/default-page.module#DefaultPageModule',
  },
];

const baseRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'redirect',
        component: LanguageRedirectContainerComponent,
      },
      {
        matcher: langRoutesMatcher,
        children: routes,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(baseRoutes, { preloadingStrategy: NoPreloading })],
})
export class AppRoutingModule {}

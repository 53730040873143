import { Component } from '@angular/core';
import { MenuService } from '../../services/menu.service';
import { Menu } from '../../classes/menu';
import {Actions, Effect} from '@ngrx/effects';
import {LanguageActionTypes, LanguageSelectAction} from '../../modules/language/store/actions/language.actions';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
    public menu: Menu;
    constructor(private menuService: MenuService, private actions$: Actions) {
      menuService.getMenu().subscribe(menu => this.menu = menu);
    }
    @Effect()
    select$ = this.actions$.ofType<LanguageSelectAction>(LanguageActionTypes.LanguageSelectAction).pipe().subscribe((action) => {
      this.menu = this.menuService.getMenuByLang(action.payload.langId);
    });
}

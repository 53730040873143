import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {PeopleInterface} from '../../../interfaces/people.interface';

@Component({
  selector: 'app-people-input',
  templateUrl: './people.input.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./people.input.component.scss']
})
export class PeopleInputComponent {
  @Input() people: PeopleInterface;
  @Input() open: boolean;
  @Output() peopleClick: EventEmitter<any> = new EventEmitter();
  onPeopleClick($event) {
    this.peopleClick.emit($event);
  }
}

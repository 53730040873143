import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LanguageModule } from '../../modules/language/language.module';
import { HomePageRoutingModule } from './home-page-routing.module';
import { HomePageContainerComponent } from './home-page-container.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { SearchModule } from '../../modules/search/search.module';

@NgModule({
  imports: [CommonModule, LanguageModule, HomePageRoutingModule, SearchModule],
  declarations: [HomePageContainerComponent, HomePageComponent],
})
export class HomePageModule {}

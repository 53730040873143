import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {FormsModule} from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule, MatNativeDateModule, DateAdapter, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ClickOutsideModule } from 'ng-click-outside';
import { CommonModule } from '@angular/common';

import { SearchComponent } from '../../components/search/search.component';
import {PlaceComponent} from '../../components/place/place.component';
import {PeopleComponent} from '../../components/people/people.component';
import {ButtonComponent} from '../../components/button/button.component';
import {RangeDatePickerComponent} from '../../components/range-date-picker/range.date.picker.component';
import {PeopleInputComponent} from '../../components/people/people-input-component/people.input.component';
import {DatePickerComponent} from '../../components/date-picker/datepicker.component';
import {PeopleModalPickerComponent} from '../../components/people/people-modal-picker-component/people.modal.picker.component';
import {PlaceDropdownComponent} from '../../components/place/place-dropdown-component/place.dropdown.component';
import {NumberpickerComponent} from '../../components/numberpicker/numberpicker.component';
import {PeoplePickerComponent} from '../../components/people/people-picker-component/people.picker.component';
import {PlaceInputComponent} from '../../components/place/place-input-component/place.input.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ErrorModalComponent} from '../../components/button/error-modal-component/error.modal.component';
import { SilpoVrModalComponent } from '../../components/silpo-vr-modal/silpo.vr.modal.component';
import { TextMaskModule } from 'angular2-text-mask';

// import {TranslateModule} from '@ngx-translate/core';
import { LanguageModule } from '../language/language.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatListModule,
    MatAutocompleteModule,
    MatDialogModule,
    ClickOutsideModule,
    // TranslateModule.forRoot()
    LanguageModule,
    FontAwesomeModule,
    TextMaskModule
  ],
  declarations: [SearchComponent, PlaceComponent, PlaceInputComponent, PlaceDropdownComponent,
    DatePickerComponent,
    RangeDatePickerComponent,
    PeopleComponent, PeopleInputComponent, PeoplePickerComponent, PeopleModalPickerComponent,
    ButtonComponent, ErrorModalComponent,
    NumberpickerComponent,
    SilpoVrModalComponent
  ],
  exports: [SearchComponent, RouterModule],
  bootstrap: [
    SearchComponent
  ],
  entryComponents: [
    PeopleModalPickerComponent,
    ErrorModalComponent,
    SilpoVrModalComponent
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: [] }
  ]
})
export class SearchModule {

}

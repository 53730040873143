import {MenuItemInterface} from '../interfaces/menu.item.interface';

export class Menu {
  public main: MenuItemInterface [];
  public footer: MenuItemInterface [];
  constructor(defaultValues) {
    this.main = defaultValues.main;
    this.footer = defaultValues.footer;
  }
}

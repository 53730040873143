import {Component, Input, Output, EventEmitter, ViewChild, ViewEncapsulation, OnInit} from '@angular/core';
import {PlaceInterface} from '../../../interfaces/place.interface';
import {LocationInterface} from '../../../interfaces/location.interface';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-place-input',
  templateUrl: './place.input.component.html',
  styleUrls: ['./place.input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlaceInputComponent implements OnInit {
  faMapMarkerAlt = faMapMarkerAlt;
  @Input() place: PlaceInterface;
  @Input() showInput: boolean;
  @Input() showResult: boolean;
  @Input() location: LocationInterface;
  @Output() placeChange: EventEmitter<any> = new EventEmitter();
  @Output() resultClick: EventEmitter<any> = new EventEmitter();
  @Output() onInit: EventEmitter<any> = new EventEmitter();
  @ViewChild('placeInput') placeInput;
  ngOnInit() {
    this.onInit.emit(this.placeInput);
  }
  placeChanged($event) {
    this.placeChange.emit($event);
  }
  onResultClick($event) {
    this.resultClick.emit($event);
    const placeInput = this.placeInput;
    setTimeout(function () {
      placeInput.nativeElement.focus();
    }, 200);
  }
}

import { AfterContentInit, ContentChild, Directive, Inject, OnDestroy } from '@angular/core';
import { RouterLink, RouterLinkWithHref } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { DEFAULT_LANG_ID } from '../tokens/default-lang-id.token';
import { ACCEPTED_LANG_IDS } from '../tokens/accepted-lang-ids.token';
import { getCurrentLangId } from '../store/selectors/language.selectors';
import { removeLangIdFromPathHelper } from '../helpers/remove-lang-id-from-path.helper';

@Directive({
  selector: '[routerLink][appLangRouterLink]',
})
export class LangRouterLinkDirective implements AfterContentInit, OnDestroy {
  @ContentChild(RouterLinkWithHref) routerLinkWithHref: RouterLinkWithHref | undefined;
  @ContentChild(RouterLink) routerLink: RouterLink | undefined;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<any>,
    @Inject(DEFAULT_LANG_ID) private defaultLangId: string,
    @Inject(ACCEPTED_LANG_IDS) private acceptedLangIds: string[],
  ) {}

  ngAfterContentInit(): void {
    this.store
      .select(getCurrentLangId)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((currentLangId: string | null): currentLangId is string => currentLangId !== null),
      )
      .subscribe((currentLangId: string) => {
        let langIdUrlPart = '';

        if (currentLangId !== this.defaultLangId) {
          langIdUrlPart = '/' + currentLangId;
        }

        if (this.routerLink !== undefined) {
          this.routerLink.routerLink =
            langIdUrlPart + removeLangIdFromPathHelper(this.routerLink.urlTree.toString(), this.acceptedLangIds);
        } else if (this.routerLinkWithHref !== undefined) {
          this.routerLinkWithHref.routerLink =
            langIdUrlPart +
            removeLangIdFromPathHelper(this.routerLinkWithHref.urlTree.toString(), this.acceptedLangIds);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

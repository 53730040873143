import { MatDialog } from '@angular/material';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { SilpoVrModalComponent } from '../components/silpo-vr-modal/silpo.vr.modal.component';
import { environment } from '../../environments/environment';
import { SearchFormService } from './search.form.service';


import { SearchInterface } from '../interfaces/search.interface';

@Injectable()
export class SilpoVrService {
  public dialogRef;
  public vrNumber: string;
  public dialogData: {
    vrNumber: string;
    onClose: Function;
  };
  private values: SearchInterface;

  constructor(public dialog: MatDialog, private http: HttpClient, private searchFormService: SearchFormService) {
    this.dialogData = {
      vrNumber: null,
      onClose: async (vrNumber) => {
        this.dialogRef.close(true);
        await this.saveSilpoOwnAccount(vrNumber);
      }
    };
  }

  openDialog(values: SearchInterface) {
    this.values = values;
    this.dialogRef = this.dialog.open(SilpoVrModalComponent, {
      data: this.dialogData
    });
    this.dialogRef.beforeClose().subscribe(
      async (result) => {
        // If cancel button clicked
        if (result) {
          // await this.saveSilpoOwnAccount(this.dialogData.vrNumber);
          this.values.vrNumber = this.dialogData.vrNumber;
        }
        this.searchFormService.startSearch();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  saveSilpoOwnAccount(silpoOwnAccount) {
    const headers = new HttpHeaders().set('api-key', environment.bookingApiKey);
    const body = { silpoOwnAccount };

    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiUrl}silpoOwnAccount`, body, { observe: 'response', headers }).subscribe(
        (httpResponse: HttpResponse<any>) => {
          if (httpResponse.body.success) {
            resolve();
          } else {
            reject(httpResponse.body.error);
          }
        },
        (err) => {
          console.error(err);
          if (err.status === 422) {
            reject(err.error.errors);
          }
        }
      );
    });
  }
}

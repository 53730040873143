import { Injectable } from '@angular/core';
import { Menu } from '../classes/menu';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getCurrentLangId } from './../modules/language/store/selectors/language.selectors';
import { filter, first, map } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable()

export class MenuService {
    public currentLangId$: Observable<string | null>;
    constructor(private store: Store<any>) {
      this.currentLangId$ = this.store.select(getCurrentLangId);
    }
    getMenuByLang(langId): Menu {
      const langPrefix = (langId === environment.defaultLangId) ? `${langId}/` : '';
      const mainPagePrefix = (langId === environment.defaultLangId) ? '' : `${langId}/`;
      const siteUrl = 'https://www.silpovoyage.ua/';
      return new Menu({
        main: [
          {text: 'Tours', link: `${siteUrl}${mainPagePrefix}`, type: 'tours', active: false},
          {text: 'AirTickets', link: `https://avia.silpovoyage.ua/${mainPagePrefix}`, type: 'avia', active: false},
          {text: 'Booking', link: `https://hotels.silpovoyage.ua/${mainPagePrefix}`, type: 'booking', active: true},
          {text: 'Offices', link: `${siteUrl}${langPrefix}offices`, type: 'offices', active: false},
          {text: 'Business', link: `${siteUrl}${langPrefix}business`, type: 'business', active: false},
        ],
        footer: [
          {text: 'About', link: `${siteUrl}${langPrefix}about`, type: 'about', active: false},
          {text: 'OpenPositions', link: `${siteUrl}${langPrefix}vakansii`, type: 'vakansii', active: false},
          {text: 'Countries', link: `${siteUrl}${langPrefix}countries`, type: 'countries', active: false},
          {text: 'Hotels', link: `${siteUrl}${langPrefix}hotels`, type: 'hotels', active: false},
          {text: 'Media', link: `${siteUrl}${langPrefix}smi`, type: 'smi', active: false},
          {text: 'TouristVocabulary', link: `${siteUrl}${langPrefix}slovar-turista`, type: 'slovar_turista', active: false},
          {text: 'Reviews', link: `${siteUrl}${langPrefix}reviews`, type: 'reviews', active: false},
          {text: 'PrivacyPolicy', link: `${siteUrl}${langPrefix}page/privacy-policy`, type: 'privacy-policy', active: false}
        ],
      });
    }
    getMenu(): Observable<Menu> {
        return this.currentLangId$.pipe(
          filter((currentLangId: string | null): currentLangId is string => currentLangId !== null),
          first(),
          map(this.getMenuByLang)
        );
    }
}

import { Component, OnInit, Input } from '@angular/core';
import { SearchFormService } from '../../services/search.form.service';

@Component({
  selector: 'app-range-date-picker',
  templateUrl: './range.date.picker.component.html',
  styleUrls: ['./range.date.picker.component.scss'],
})
export class RangeDatePickerComponent implements OnInit {
  @Input() touchUi: boolean;

  public values;
  constructor(private searchFormService: SearchFormService) { }

  ngOnInit() {
    this.values = this.searchFormService.getValues();
  }

  public onCheckInChange($event) {
    const newCheckOutMinDate = $event.value.clone().add(1, 'days');
    this.values.checkOut.min = newCheckOutMinDate;
    this.values.checkOut.max = $event.value.clone().add(30, 'days');
    if (this.values.checkOut.date < $event.value) {
      this.values.checkOut.date = newCheckOutMinDate;
    }
    if (this.values.checkOut.date >  this.values.checkOut.max) {
      this.values.checkOut.date = this.values.checkOut.max;
    }
    this.searchFormService.onChange();
  }

  public onCheckOutChange($event) {
    this.searchFormService.onChange();
  }
}

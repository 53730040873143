import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';


if (environment.production) {
  enableProdMode();
}
declare const require;

platformBrowserDynamic().bootstrapModule(AppModule, {
    providers: getTranslationProviders()
})
  .catch(err => console.log(err));

function getTranslationProviders() {
  // const pathArray = window.location.pathname.split( '/' );
  // if (pathArray.length > 0 && pathArray[1] && pathArray[1] === 'uk') {
  //   const translations = require(`raw-loader!./locale/messages.uk_UA.xlf`);
  //   return [
  //     { provide: TRANSLATIONS, useValue: translations },
  //     { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' }
  //   ];
  // }
  return [];
}

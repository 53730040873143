import { createFeatureSelector } from '@ngrx/store';

import { LanguageActions, LanguageActionTypes } from '../actions/language.actions';

export interface LanguageState {
  currentLangId: string | null;
}

export const initialLanguageState: LanguageState = {
  currentLangId: null,
};

export function languageReducer(state = initialLanguageState, action: LanguageActions): LanguageState {
  switch (action.type) {
    case LanguageActionTypes.LanguageUrlChangeAction: {
      return {
        ...state,
        currentLangId: action.payload.langId,
      };
    }
  }

  return state;
}

export const getLanguageState = createFeatureSelector<LanguageState>('Language');

import { Injectable } from '@angular/core';
import { filter, first, map } from 'rxjs/operators';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { SearchLocationService } from './search.location.service';
import { Observable } from 'rxjs/internal/Observable';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { merge } from 'lodash';

import { WindowRef } from './window.ref';
import { getCurrentLangId } from '../modules/language/store/selectors/language.selectors';
import { LanguageActionTypes, LanguageSelectAction } from '../modules/language/store/actions/language.actions';

import { Search } from '../classes/search';
import { LocationResultsInterface } from '../interfaces/location.results.interface';
import { SearchInterface, LocalStorage } from '../interfaces/search.interface';

const STORAGE_VAR = 'SearchFormValues';
const AFFILIATE_ID = '1349473';
const MAX_LOCAL_STORAGE_LIFETIME = 60 * 60 * 1000; // 1 hour

@Injectable()
export class SearchFormService {
  private values: SearchInterface;
  private winRef;
  private currentLangId;
  private currentLangId$;

  constructor (
    private searchLocationService: SearchLocationService, private store: Store<any>, private actions$: Actions
  ) {
    this.currentLangId$ = this.store.select(getCurrentLangId);
    this.currentLangId$.pipe(
      filter((currentLangId: string | null): currentLangId is string => currentLangId !== null),
      first(),
      map((currentLangId) => {
        this.currentLangId = currentLangId;
      })
    ).subscribe();
    this.winRef = new WindowRef();
    this.values = merge(this.getDefaultValues(), this.getFromStorage());
  }

  @Effect()
  select$ = this.actions$.ofType<LanguageSelectAction>(LanguageActionTypes.LanguageSelectAction).pipe().subscribe((action) => {
    this.currentLangId = action.payload.langId;
  });

  getValues() {
    return this.values;
  }

  validateValues() {
    if (typeof this.values.place.location === 'undefined') {
      return {field: 'input_place', error_code: 'empty'};
    }
    return;
  }

  async changePlace(newPlaceText: string, success: () => void) {
    this.searchLocationService.getLocations(newPlaceText)
      .subscribe((resp1: Observable<HttpResponse<LocationResultsInterface>>) => {
        resp1.subscribe((resp: HttpResponse<LocationResultsInterface>) => {
          if (resp.ok) {
            const res = resp.body;
            if (res.success) {
              this.values.locations = res.results.map((result) => {
                return result;
              });
              success();
            }
          }
        });

      }
    , err => {
      console.error('ChangePlace failed', err);
    });
  }

  startSearch() {
    this.winRef.nativeWindow.open(this.getBookingSearchLink(), '_blank');
  }

  getBookingSearchLink() {
    let room1 = Array(this.values.people.adults + 1).join('A').split('').join(',');
    if (this.values.people.children.length > 0) {
      room1 += ',' + this.values.people.children.map((c) => c.age).join(',');
    }
    if (typeof this.values.place.location === 'undefined') {
      throw new Error('No location selected');
    }
    const params: any = {
      aid: AFFILIATE_ID,
      checkin_monthday: this.values.checkIn.date.get('date').toString(),
      checkin_month: (this.values.checkIn.date.get('month') + 1).toString(),
      checkin_year: this.values.checkIn.date.get('year').toString(),
      checkout_monthday: this.values.checkOut.date.get('date').toString(),
      checkout_month: (this.values.checkOut.date.get('month') + 1).toString(),
      checkout_year: this.values.checkOut.date.get('year').toString(),
      group_adults: this.values.people.adults.toString(),
      dest_type: this.values.place.location.type,
      dest_id: this.values.place.location.id.toString(),
      ss_raw: this.values.place.text,
      room1: room1,
    };
    if (this.values.vrNumber) {
      params.label = `silpo-own-accout-${this.values.vrNumber}`;
    }

    let httpParams: HttpParams = new HttpParams();

    for (const key in params) {
      httpParams = httpParams.set(key, params[key]);
    }

    const url = `http://www.booking.com/searchresults.${this.currentLangId}.html`;
    return `${url}?${httpParams.toString()}`;
  }

  onChange() {
    this.saveToStorage();
  }

  private getDefaultValues(): Search {
    return new Search(merge({
      place: {
        text: ''
      },
      checkIn: {
        min: moment().add(2, 'hours'),
        max: moment().add(360, 'days'),
        date: moment().add(3, 'hours')
      },
      checkOut: {
        min:  moment().add(24 + 2, 'hours'),
        max: moment().add(360 + 1, 'days'),
        date: moment().add(24 + 3, 'hours')
      },
      people: {
        adults: 2,
        adultsMax: 30,
        children: [],
        childrenMax: 10
      },
      locations: [],
      vrNumber: null,
      bookingId: null
    }, this.getFromStorage()));
  }

  private saveToStorage() {
    const res: LocalStorage = {
      timestamp: new Date().valueOf(),
      searchForm: {
        people: {
          adults: this.values.people.adults,
          children: this.values.people.children
        },
        checkIn: {
          date: this.values.checkIn.date
        },
        checkOut: {
          date: this.values.checkOut.date
        },
        vrNumber: this.values.vrNumber,
      }
    };
    if (this.values.place.location) {
      res.searchForm.place = {
        text: this.values.place.text,
        location: this.values.place.location
      };
    }
    localStorage.setItem(STORAGE_VAR, JSON.stringify(res));
  }

  private getFromStorage(): LocalStorage['searchForm'] {
    const storage: string = localStorage.getItem(STORAGE_VAR);
    if (!storage) {
      return null;
    }

    const parsed: LocalStorage = JSON.parse(storage);
    if (!parsed) {
      return null;
    } else {
      parsed.searchForm.checkIn.date = moment(parsed.searchForm.checkIn.date);
      parsed.searchForm.checkOut.date = moment(parsed.searchForm.checkOut.date);
    }
    if (new Date().valueOf() - parsed.timestamp > MAX_LOCAL_STORAGE_LIFETIME) {
      localStorage.setItem(STORAGE_VAR, null);

      return null;
    }

    return parsed.searchForm;
  }
}

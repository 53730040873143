import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-silpo-vr-modal',
  templateUrl: './silpo.vr.modal.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./silpo.vr.modal.component.scss']
})
export class SilpoVrModalComponent {
  @ViewChild('silpoOwnAccountForm') silpoOwnAccountForm;
  public data: {
    vrNumber: string;
    onClose: Function;
  };
  public silpoCardMask: RegExp[] = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

  constructor(@Inject(MAT_DIALOG_DATA) data: { vrNumber: string, onClose: Function }) {
    this.data = data;
  }

  clickOk() {
    if (this.silpoOwnAccountForm.form.controls.ownAccountInput.errors) {
      const errors = Object.assign(this.silpoOwnAccountForm.form.controls.ownAccountInput.errors, {'incorrect': false});
      this.silpoOwnAccountForm.form.controls.ownAccountInput.setErrors(errors);
    }

    if (this.silpoOwnAccountForm.form.valid) {
      this.data.onClose(this.data.vrNumber).catch((errors) => {
        if (errors && errors.silpoOwnAccount) {
          this.silpoOwnAccountForm.form.controls.ownAccountInput.setErrors({'incorrect': true});
        }
      });
    }
  }
}

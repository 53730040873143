import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {LocationInterface} from '../../../interfaces/location.interface';

@Component({
  selector: 'app-place-dropdown',
  templateUrl: './place.dropdown.component.html',
  styleUrls: ['./place.dropdown.component.scss'],
})
export class PlaceDropdownComponent implements OnInit {
  public _options: LocationInterface[];
  @Input() show: boolean;
  @Input()
  set options(options: LocationInterface[]) {
    this._options = options;
  }
  @Output() locationSelected: EventEmitter<any> = new EventEmitter();
  ngOnInit() {
    this._options = [];
  }
  optionSelected(option) {
    this.locationSelected.emit(option);
  }
}

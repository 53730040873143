import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material';

import { LanguageSelectContainerComponent } from './language-select.container.component';
import { LanguageSelectComponent } from './components/language-select/language-select.component';

@NgModule({
  imports: [CommonModule, MatSelectModule],
  declarations: [LanguageSelectContainerComponent, LanguageSelectComponent],
  exports: [LanguageSelectContainerComponent],
})
export class LanguageSelectModule {}

import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(private meta: Meta, private title: Title, private translate: TranslateService) {}

  setTitle(title: string): void {
    this.translate.get(title).subscribe((res: string) => {
      this.title.setTitle(res);
    });
  }

  setMetaDescription(description: string): void {
    this.translate.get(description).subscribe((res: string) => {
      this.meta.updateTag({
        name: 'description',
        content: res,
      });
    });
  }

  setTitleDescription(title: string, description: string): void {
    this.setTitle(title);
    this.setMetaDescription(description);
  }
  setMetaKeywords(keywords: string): void {
    this.meta.updateTag({
      name: 'keywords',
      content: keywords,
    });
  }
}

import { SearchInterface } from '../interfaces/search.interface';
import { PlaceInterface } from '../interfaces/place.interface';

export class Search implements SearchInterface {
  public place: PlaceInterface;
  public checkIn;
  public checkOut;
  public people;
  public locations;
  public vrNumber;
  public bookingId;

  constructor(defaultValues) {
    this.place = defaultValues.place;
    this.checkIn = defaultValues.checkIn;
    this.checkOut = defaultValues.checkOut;
    this.people = defaultValues.people;
    this.locations = defaultValues.locations;
    this.vrNumber = null;
    this.bookingId = null;
  }
}

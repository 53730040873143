import { NgModule } from '@angular/core';
import { Optional, SkipSelf } from '@angular/core';
import { PlatformQueryService } from './services/platform-query.service';
import { EnvironmentQueryService } from './services/environment-query.service';



@NgModule({
  providers: [
    PlatformQueryService,
    EnvironmentQueryService
  ]
})
export class EnvironmentModule {
  constructor(
    @Optional()
    @SkipSelf()
      parentModule: EnvironmentModule) {
    if (parentModule) {
      throw new Error('RouterStoreModule is already loaded.');
    }
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-language-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LanguageSelectComponent {
  public languages = {
    ru: 'РУС',
    uk: 'УКР'
  };
  @Input() currentLangId: string | null;
  @Input() acceptedLangIds: string[];

  @Output() selectValueEvent = new EventEmitter<string>();
}

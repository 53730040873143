import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';

import { ACCEPTED_LANG_IDS } from './tokens/accepted-lang-ids.token';
import { DEFAULT_LANG_ID } from './tokens/default-lang-id.token';
import { languageReducer } from './store/reducers/language.reducer';
import { LanguageEffects } from './store/effects/language.effects';
import { LangRouterLinkDirective } from './directives/lang-router-link.directive';
import { LanguageRedirectContainerComponent } from './containers/language-redirect-container.component';
import { LanguageInitCommandService } from './services/commands/language-init-command.service';

@NgModule({
  imports: [StoreModule.forFeature('Language', languageReducer), EffectsModule.forFeature([LanguageEffects])],
  declarations: [LangRouterLinkDirective, LanguageRedirectContainerComponent],
  exports: [LangRouterLinkDirective, LanguageRedirectContainerComponent, RouterModule, TranslateModule],
})
export class LanguageModule {
  constructor(languageInitCommandService: LanguageInitCommandService) {
    languageInitCommandService.use();
  }

  static forRoot(acceptedLangIds: string[], defaultLangId: string): ModuleWithProviders {
    return {
      ngModule: LanguageModule,
      providers: [
        { provide: ACCEPTED_LANG_IDS, useValue: acceptedLangIds },
        { provide: DEFAULT_LANG_ID, useValue: defaultLangId },
      ],
    };
  }
}
